import React from 'react';
import Btn from '~/components/src/Btn';
import { WORKFLOW_EVENTS } from '~/gaActions';
import i18n from '~/i18n';
import { WORKFLOW_STATUS } from '~/workflows/constants';
import { TWorkflowState } from '~/workflows/types';

export interface PublishButtonProps {
  isDemo?: boolean;
  isLatestVersion?: boolean;
  canEdit: boolean;
  state: TWorkflowState;
  validateJourney: (isHpRunningOrScheduled: boolean) => void;
  isHpRunningOrScheduled: boolean;
}

const PublishButton = ({
  isDemo,
  isLatestVersion,
  canEdit,
  state,
  validateJourney,
  isHpRunningOrScheduled,
}: PublishButtonProps) => {
  function getTooltip() {
    if (!canEdit && state.name !== WORKFLOW_STATUS.PUBLISHED)
      return i18n.t('workflow:orchestration.permissions.notEnoughPermissions');
    else if (isDemo && !isLatestVersion) {
      return 'You are tyring to publish old version';
    }
    return '';
  }

  function shouldDisable() {
    if (isDemo && !isLatestVersion) {
      return false;
    } else if (state.name === WORKFLOW_STATUS.PUBLISHED || !canEdit) {
      return true;
    }
    return false;
  }
  return (
    <Btn
      color="blue"
      onClick={() => {
        validateJourney(isHpRunningOrScheduled);
      }}
      testHook="journey-publish"
      disabled={shouldDisable()}
      tooltip={getTooltip()}
      gaAction={WORKFLOW_EVENTS.PUBLISHED}
    >
      {i18n.t('workflow:orchestration.publish.publish')}
    </Btn>
  );
};

export default PublishButton;
