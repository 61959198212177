import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Field, FormSection } from 'redux-form';
import { defaultMaxInput, isPositiveNumber, isRequired, isValidVariableName, isValueUnique, minLength } from '~/common';
import BtnOutlined from '~/components/src/BtnOutlined';
import { Panel } from '~/components/src/Containers';
import { ReduxFormInputField, ReduxFormSelectField } from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { VARIABLE_EVENTS } from '~/gaActions';
import UsageIcons from './UsageIcons';
import enhanced from './VariablesListCompose';
import './VariablesList.scss';

const { Table, Row, RowActions, Cell, EditableRow, EditableRowControls, HeaderCell } = components;
const { withSort, withPaginate, withFilter } = enhancements;

export const variableTypes = [
  { value: 'STRING', label: 'STRING' },
  { value: 'LIST', label: 'LIST' },
];
export const variableUsedIn = ['SELECTION', 'AD', 'JOURNEY'];

const isFormFieldUnique = fieldName => (value, allValues, props) => {
  let itemsList = [];
  if (props) {
    itemsList = props.list.map(item => item[fieldName]);
  }
  if (props.initialValues) {
    itemsList = itemsList.filter(item => item !== props.initialValues[fieldName]);
  }
  return isValueUnique(
    value.toUpperCase(),
    itemsList.map(item => item.toUpperCase()),
  );
};

const isNameUnique = isFormFieldUnique('name');
const minVariableNameLength = minLength(3);

export class VariablesList extends Component {
  handleResetVariableSize() {
    this.props.change('variableOptions.size', null);
  }
  render() {
    const {
      stateKey,
      t,
      list,
      onDeleteClick,
      showAddNewItemRow = false,
      shouldShowAddNewItemRow,
      onDependantIconClick,
      variableType,
      handleSubmit,
      submitting,
      get,
      destroy,
      selectedVariable,
      touch,
      canEdit,
    } = this.props;
    const variableTypeName = get(variableType, 'name', null);
    return (
      <Panel>
        <Table stateKey={stateKey} testHook="variablesListTable">
          <Row>
            <SortableHeader className="u-size1of4" sortKey="name" title={t('common.variableName')} />
            <HeaderCell className="u-size1of4 u-marginLeftM">{t('common.variableType')}</HeaderCell>
            <HeaderCell className="u-size1of4"> {t('common.variableOptionsSize')}</HeaderCell>
            <HeaderCell className="u-size2of12">{t('common.usedIn')}</HeaderCell>
            {canEdit && <HeaderCell className="u-size1of12"> </HeaderCell>}
          </Row>
          {list.map(variable => {
            const {
              name,
              variableId,
              variableType: { label },
              dependantTypes,
            } = variable;
            const size = get(variable, 'variableOptions.size', null);
            const actions = [
              {
                name: t('common:actions.remove'),
                tooltip:
                  dependantTypes.length > 0
                    ? t('variables:list.removeActionDisabledTooltip')
                    : t('common:actions.remove'),
                isDisabled: dependantTypes.length > 0,
                icon: 'delete',
                onClick: () => onDeleteClick(variable, t),
                testHook: 'delete',
                type: 'delete',
              },
            ];
            const isSelected = selectedVariable && variable.variableId === selectedVariable.variableId;
            return (
              <Row
                key={variableId}
                withActions
                className={cx('u-posRelative', { isSelected }, `t-${name}Row`, 't-variableListTableRow')}
              >
                <Cell className="u-size1of4">{name}</Cell>
                <Cell className="u-size1of4 u-marginLeftM">{label.toUpperCase()}</Cell>
                <Cell className="u-size1of4">{size || '-'}</Cell>
                <Cell className="u-size2of12">
                  <UsageIcons
                    dependantTypes={dependantTypes}
                    elementUsedIn={variableUsedIn}
                    elementId={variableId}
                    onDependantIconClick={onDependantIconClick}
                  />
                </Cell>
                {canEdit && onDeleteClick && <RowActions actions={actions} className="u-size1of12" />}
              </Row>
            );
          })}
          <EditableRow isVisible={showAddNewItemRow}>
            <Form onSubmit={handleSubmit} isSubmitting={submitting} className="VariablesForm">
              <Field
                name="name"
                component={ReduxFormInputField}
                autoFocus={true}
                validate={[isRequired, defaultMaxInput, minVariableNameLength, isValidVariableName, isNameUnique]}
                testHook="variableNameInput"
              />
              <FormSection name="variableType">
                <Field
                  name="name"
                  component={ReduxFormSelectField}
                  onChange={() => {
                    this.handleResetVariableSize();
                  }}
                  isLoading={false}
                  options={variableTypes}
                  validate={[isRequired]}
                  testHook="variableTypeInput"
                  touch={touch}
                />
              </FormSection>
              <FormSection name="variableOptions">
                {variableTypeName === 'LIST' && (
                  <Field
                    name="size"
                    component={ReduxFormInputField}
                    validate={[isRequired, isPositiveNumber]}
                    testHook="listSizeInput"
                  />
                )}
              </FormSection>
              <EditableRowControls
                onHandleCancel={() => {
                  destroy();
                  shouldShowAddNewItemRow(false);
                }}
                gaAction={VARIABLE_EVENTS.CREATED}
                trackGa
              />
            </Form>
          </EditableRow>
          {canEdit && (
            <BtnOutlined
              testHook="listActionButton"
              icon="add"
              onClick={() => shouldShowAddNewItemRow(true)}
              color="blue"
              size="xs"
              className="mt-4"
            >
              {t('list.addNewVariable')}
            </BtnOutlined>
          )}
        </Table>
      </Panel>
    );
  }
}

export default enhanced(compose(withFilter(), withSort(), withPaginate())(VariablesList));

VariablesList.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string,
  t: PropTypes.func,
  reset: PropTypes.func,
  onEditClick: PropTypes.func,
  submitting: PropTypes.bool,
  formSyncErrors: PropTypes.object,
  shouldShowAddNewItemRow: PropTypes.func,
  showAddNewItemRow: PropTypes.bool,
  onDependantIconClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  touch: PropTypes.func.isRequired,
  get: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isEmpty: PropTypes.func,
  variableType: PropTypes.object,
};
