import React, { MouseEvent } from 'react';
import cx from 'classnames';
import '~/components/src/Form/Elements/elements.scss';
import BtnIcon from '../../BtnIcon';
import icons from '../../Icons/icons';

interface InputElementProps {
  name?: string;
  value?: string;
  disabled?: boolean;
  defaultValue?: string | number;
  placeholder?: string;
  type?: string;
  testHook?: string;
  hasError?: boolean;
  className?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  min?: number;
  max?: number;
  id?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  maxLength?: number;
  readOnly?: boolean;
  enableAction?: boolean;
  buttonText?: string;
  buttonChildren?: React.ReactElement;
  onToggleButton?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  error?: string;
  buttonIcon?: keyof typeof icons;
  buttonTooltip?: string;
  buttonTestHook?: string;
}

const InputElement = ({
  name,
  value,
  disabled,
  defaultValue,
  placeholder = 'Please provide a value',
  type = 'text',
  testHook,
  hasError,
  className,
  onKeyDown,
  onKeyPress,
  onChange,
  onFocus,
  onBlur,
  min,
  max,
  id,
  autoFocus,
  autoComplete,
  maxLength,
  readOnly = false,
  onToggleButton = () => ({}),
  enableAction = false,
  buttonTooltip,
  buttonIcon,
  buttonTestHook,
  error,
}: InputElementProps): React.ReactElement => (
  <div className="InputElement">
    <div className={cx({ 'InputElement-withAction': enableAction })}>
      <input
        name={name}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        min={min}
        max={max}
        defaultValue={defaultValue}
        className={cx(
          'InputElement',
          { 'InputElement--hasError': hasError, [`t-${testHook || name}`]: testHook || name },
          className,
        )}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        maxLength={maxLength}
        readOnly={readOnly}
      />
      {enableAction && buttonIcon && (
        <BtnIcon
          className="h-auto w-12 p-2"
          onClick={onToggleButton}
          icon={buttonIcon}
          tooltip={buttonTooltip}
          testHook={buttonTestHook}
        ></BtnIcon>
      )}
    </div>
    {error && <small className="InputElement-error">{error}</small>}
  </div>
);

export default InputElement;
